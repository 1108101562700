import React, { Fragment } from 'react';
import { Link } from 'gatsby'
import Logo from '../components/graphics/logo';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Paragraph from '../components/blocks/Paragraph';
import PageTitle from '../components/blocks/PageTitle';

const NotFoundPage = () => {
  return (
    <Fragment>
      <HelmetDatoCms title="Bezpieczny Balkon - 404: Not found"></HelmetDatoCms>
      <div className='c_container text-text h-screen flex flex-col justify-center'>
        <div className='flex flex-col mb-16 sm:text-center sm:mb-0 space-y-md'>
          <Link to='/' className='mb-6 sm:mx-auto w-1/3'>
            <Logo></Logo>
          </Link>
          <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12'>
            <PageTitle className='max-w-lg mb-6 md:mx-auto'>
              404
            </PageTitle>
            <Paragraph>
              Strona o podanym adresie nie istnieje :|
            </Paragraph>
          </div>
          <div>
            <Link
              className='w-full md:w-auto button-main c_interactive bg-primary text-white active:outline-none active:bg-primaryDark'
              to='/'
              title='Home'>
              Wróć na stronę główną
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NotFoundPage
